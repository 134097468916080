import React from 'react';
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { joinClasses } from '../../../../../utils';
import { Field } from '../../../../../utils/forms';
import styles from '../../styles';
import AddressModal from '../../../Modals/Address';

const SavedAddresses = ({
  classes,
  context,
  method,
  user,
  stateOptions,
  states,
  countryOptions,
  addressBookId,
  setNewAddress,
  clearAddress,
  addressInvalid,
  handleAddressBookChange,
  formValues,
  address2,
  company,
  saveAsDefaultAllowed,
  onMakeDefaultAddress,
  isMarkedSaveDefault,
  changeState,
  selectedCountry,
  overrideCountry,
  isBulk,
  handleBlur,
  addressSuggestions,
  onSelectSuggestedAddress,
  change,
  searchAddress,
  handleSelect,
  onSearchAddress,
  shippingAddress
}) => {

  const [modalActive, setModalActive] = React.useState(false);
  const [containerExpanded, setContainerExpanded] = React.useState(false);
  // const isDelivery = method === DELIVER
  const [missingAddress1, setMissingAddress1] = React.useState(false);

  const { AddressBook } = user || {};

  const {
    name_first,
    name_last,
    address1,
    address2: address2Display,
    city,
    StateShort,
    zip,
    stateId
  } = formValues || {};

  const st = (stateOptions || []).find(({ value }) => value === stateId) || {};

  const renderAddress = ({ FirstName, LastName, Address1, Address2, City, StateShort, Zip, AddressBookID }, index) => {
    const label = `${FirstName} ${LastName}, ${Address1} ${Address2 || ''} ${City}, ${StateShort} ${Zip}`;
    return (
      <FormControlLabel
        key={index}
        value={AddressBookID}
        control={<Radio color="primary" />}
        label={label}
        labelPlacement="end"
        className={classes.address}
      />
    );
  }

  const toggleContainer = () => {
    setContainerExpanded(!containerExpanded)
  }

  const openModal = e => {
    e.preventDefault();
    setModalActive(true);
  };

  const closeModal = () => {
    setModalActive(false);
  };

  const cancelModal = () => {
    //resets form back to selected
    if (addressBookId !== 'new') handleAddressBookChange(addressBookId);
    setModalActive(false);
  };

  const isNewAddress = addressBookId === 'new';

  const defaultAddress = (AddressBook || [])
    .filter(({ IsDefaultShipping, IsDefaultMailing }) => context === 'Billing' ? IsDefaultMailing : IsDefaultShipping)
    .map((item, index) => renderAddress(item, index));

  const hasDefaultAddress = (defaultAddress || []).length > 0;

  const countryName = (countryOptions || []).find(({ value }) => {
    return value === (selectedCountry || {}).countryId;
  })

  return (
    <>
      <div className={classes.savedAddresses}>
        <p className={joinClasses('h6', classes.subHeadline)}>
          {isBulk ? '' : `${context} Address`}
        </p>
        <FormControl>
          <Field
            name="addressBookId"
            normalize={value => parseInt(value)}
            onChange={(value) => handleAddressBookChange(value.target.value)}
          >
            <RadioGroup
              aria-label="choose an address"
              row
            >
              {hasDefaultAddress &&
                <div>
                  <p
                    className={joinClasses(
                      'eyebrow',
                      'color--light-gray',
                      classes.eyebrow, classes.clear
                    )}
                  >
                    Default Address
                  </p>
                  {defaultAddress}
                </div>
              }
              <p
                className={joinClasses(
                  'eyebrow',
                  'color--light-gray',
                  classes.eyebrow, classes.clear
                )}
              >
                {hasDefaultAddress ? 'Other Addresses' : 'Saved Addresses'}
              </p>
              <div className={joinClasses(classes.addressesContainer, !containerExpanded && classes.contracted)}>
                {(AddressBook || [])
                  .filter(({ IsDefaultShipping, IsDefaultMailing }) => context === 'Billing' ? !IsDefaultMailing : !IsDefaultShipping)
                  .filter((_, idx) => containerExpanded ? true : idx < 3)
                  .map((item, index) => renderAddress(item, index + 1))}
              </div>
            </RadioGroup>
          </Field>
        </FormControl>
        {(AddressBook || []).filter(({ IsDefaultShipping }) => !IsDefaultShipping).length >= 4 && (
          <button
            type="button"
            className={joinClasses(classes.button, classes.showMore)}
            onClick={toggleContainer}
            onKeyDown={toggleContainer}
          >
            {containerExpanded ? 'Show Less' : `Show more ${(context || '').toLowerCase()} addresses`}
          </button>
        )}
        {!isNewAddress && (
          <button
            type="button"
            className={classes.ctaShowMore}
            onClick={e => {
              if (clearAddress) clearAddress();
              setMissingAddress1(false)
              handleSelect({ searchAddress: true })
              openModal(e)
            }}
          >
            Enter a new {(context || '').toLowerCase()} address
          </button>
        )}
        {isNewAddress && (
          <button
            type="button"
            className={classes.ctaShowMore}
            onClick={e => {
              setMissingAddress1(false)
              openModal(e)
              handleSelect({ searchAddress: false })
            }}
          >
            Edit {(context || '').toLowerCase()} address
          </button>
        )}

        {addressBookId === 'new' && address1 && (
          <div>
            <br />
            <p>{name_first} {name_last}</p>
            <p>{address1}</p>
            {address2Display && <p>{address2Display}</p>}
            <p>{city}, {(st || {}).label || ''}</p>
            <p>{zip}, {(selectedCountry === 37 || overrideCountry) ? 'United States' : (countryName || {}).label || ''}</p>
          </div>
        )}
      </div>
      <AddressModal
        isOpen={modalActive}
        onClose={closeModal}
        onCancel={cancelModal}
        context={context}
        stateOptions={stateOptions}
        countryOptions={countryOptions}
        setNewAddress={setNewAddress}
        address2={address2}
        company={company}
        saveAsDefaultAllowed={saveAsDefaultAllowed}
        clear={addressBookId === 'new'}
        addressInvalid={addressInvalid}
        onMakeDefaultAddress={onMakeDefaultAddress}
        isMarkedSaveDefault={isMarkedSaveDefault}
        formValues={formValues}
        changeState={changeState}
        states={states}
        selectedCountry={selectedCountry}
        onSearchAddress={onSearchAddress}
        handleBlur={handleBlur}
        addressSuggestions={addressSuggestions}
        onSelectSuggestedAddress={onSelectSuggestedAddress}
        change={change}
        searchAddress={searchAddress}
        handleSelect={handleSelect}
        address1={address1}
        missingAddress1={missingAddress1}
        setMissingAddress1={setMissingAddress1}
      />
    </>
  );
};

export default withStyles(styles)(SavedAddresses);
