import { getJson, postJson, del, putJson } from './fetch';

const isLocal = process.env.REACT_APP_ENV === 'local'

const apiBase = isLocal ? `${process.env.REACT_APP_API_BASE}` : `${window.SHOP_URL}`;

const location = window.location;

// IDENTITY
export const postLogin = payload => postJson({
  path: `${apiBase}/identity/login`,
  payload
})

export const postLogout = () => postJson({
  path: `${apiBase}/identity/logout`,
  payload: {}
}).then(() => location.reload())

export const validateLogin = () => getJson({
  path: `${apiBase}/identity/validate`
});

export const postForgotPassword = payload => postJson({
  path: `${apiBase}/identity/request-password`,
  payload
});

export const postResetPassword = payload =>
  postJson({
    path: `${apiBase}/identity/reset-password`,
    payload
  });

export const postVerifyForgotPasswordToken = payload =>
  postJson({
    path: `${apiBase}/identity/reset-password/validate`,
    payload
  });

export const postVerifyAccount = payload =>
  postJson({
    path: `${apiBase}/identity/verify`,
    payload
  });

export const getCampaignUser = ({ et_uid }) =>
  getJson({
    path: `${apiBase}/identity/campaign?et_uid=${et_uid}`
  });

// END IDENTITY

export const getLookups = () => getJson({
  path: `${apiBase}/lookups`
});

// SHOP
export const postAddToCart = ({ payload }) => postJson({
  path: `${apiBase}/cart`,
  payload
});

export const deleteCartItem = ({ shoppingCartItemId, s }) => del({
  path: `${apiBase}/cart/items/${shoppingCartItemId}?s=${s || ''}`
});

export const putCartItem = ({ id, payload, s }) => putJson({
  path: `${apiBase}/cart/items/${id}?s=${s || ''}`,
  payload
});

export const getShopProductBySlug = slug => getJson({
  path: `${apiBase}/shop/products/${slug}`
});

export const getShopProducts = () => getJson({
  path: `${apiBase}/shop/products`
});

// END SHOP


// CHECKOUT
export const getCart = (payload) => getJson({
  path: `${apiBase}/cart`,
  payload
});

export const getCampaignCart = ({ et_cartid }) => getJson({
  path: `${apiBase}/cart/campaign?et_cartid=${et_cartid}`
});

export const postCartShipping = ({ payload }) => postJson({
  path: `${apiBase}/cart/shipping`,
  payload
});

export const getCartShipping = (payload) => getJson({
  path: `${apiBase}/cart/shipping`,
  payload
});

export const postCartBilling = ({ payload }) => postJson({
  path: `${apiBase}/cart/billing`,
  payload
});

export const getCartBilling = (payload) => getJson({
  path: `${apiBase}/cart/billing`,
  payload
});

export const postValidateAddress = payload => postJson({
  path: `${apiBase}/address/validate`,
  payload
});

export const postCompleteOrder = (payload) => postJson({
  path: `${apiBase}/cart/payment`,
  payload
});

export const getBulkCarts = ({ customerId }) => getJson({
  path: `${apiBase}/cart/bulk-carts?customerId=${customerId}`,
});

export const postCompleteBulkOrders = (payload) => postJson({
  path: `${apiBase}/cart/bulk-payment`,
  payload
});
export const sendBulkOrderEmail = (payload) => postJson({
  path: `${apiBase}/cart/bulk-email`,
  payload
});
export const getPollingStatus = (id) =>
  getJson({
    path: `${apiBase}/cart/polling/status/${id}`
  });

export const getCheckEmail = (payload) => postJson({
  path: `${apiBase}/identity/check-user`,
  payload
});

export const postPromoCode = payload =>
  postJson({
    path: `${apiBase}/cart/coupons`,
    payload
  });

export const delPromoCode = () =>
  del({
    path: `${apiBase}/cart/coupons`
  });
// END CHECKOUT


// ACCOUNT
export const getOrderHistory = (limit) => getJson({
  path: `${apiBase}/orders?limit=${limit}`
});

export const postNameAndEmail = ({ payload }) => postJson({
  path: `${apiBase}/account/name-and-email`,
  payload
});

export const putBirthday = ({ payload, id }) => putJson({
  path: `${apiBase}/account/birthday`,
  payload
});

export const putPassword = ({ payload, id }) => putJson({
  path: `${apiBase}/account/password`,
  payload
});

export const postEmailPref = payload => postJson({
  path: `${apiBase}/account/email-prefs`,
  payload
});

export const deleteAddress = addressId => del({
  path: `${apiBase}/account/address/${addressId}`
});

export const putAddress = ({ payload, id, addressId }) => putJson({
  path: `${apiBase}/account/address/${addressId}`,
  payload
});

export const postAddress = ({ payload, id }) => postJson({
  path: `${apiBase}/account/address`,
  payload
});

export const deleteCard = cardId => del({
  path: `${apiBase}/account/card/${cardId}`
});

export const postCard = ({ payload, id }) => postJson({
  path: `${apiBase}/account/card`,
  payload
});

export const putCard = ({ payload, id }) => putJson({
  path: `${apiBase}/account/card`,
  payload
});

export const postValidatePassword = (payload) => postJson({
  path: `${apiBase}/account/validate-password`,
  payload
});

export const postSignUp = payload => postJson({
  path: `${apiBase}/identity/sign-up`,
  payload

}).then(result => {
  return result;
});

// END ACCOUNT

export const getCheckZip = zip => getJson({
  path: `${apiBase}/address/validate/zip/${zip}`
}).catch(err => {
  return { err };
});

export const getFindOurWines = (qs) => getJson({
  path: `${apiBase}/find-our-wines?${qs || ''}`
});

export const postSubscribe = payload => postJson({
  path: `${apiBase}/contact/subscribe`,
  payload
});

export const validateEmail = payload => getJson({
  path: `${apiBase}/account/validate-email/${payload}`
}).catch(err => {
  return { err }
});

export const findFedexLocation = payload => postJson({
  path: `${apiBase}/address/hold-at-location-fedex`,
  payload
}).catch(err => {
  return { err }
});

export const getAddressSuggestions = payload => postJson({
  path: `${apiBase}/address/autocomplete-address`,
  payload
}).catch(err => {
  return { err }
});


