import duxFactory from './fetchDux/fetch-dux-factory';
import * as api from './fetchDux/api';

export default {
  // IDENTITY
  postLogin: duxFactory({
    apiFn: api.postLogin,
    actionPrefix: 'POST_LOGIN',
    successListeners: [
      'POST_ADDRESS',
      'PUT_ADDRESS',
      'POST_CARD',
      'PUT_CARD',
      'PUT_BIRTHDAY',
      'POST_VALIDATE_LOGIN',
      'POST_LOGOUT',
      'POST_EMAIL_PREF',
      'DEL_ADDRESS',
      'DEL_CARD',
      'POST_NAME_AND_EMAIL'
    ]
  }),
  postLogout: duxFactory({
    apiFn: api.postLogout,
    actionPrefix: 'POST_LOGOUT'
  }),
  validateLogin: duxFactory({
    apiFn: api.validateLogin,
    persist: true,
    actionPrefix: 'POST_VALIDATE_LOGIN'
  }),
  postForgotPassword: duxFactory({
    apiFn: api.postForgotPassword,
    actionPrefix: 'POST_FORGOT_PASSWORD'
  }),
  postResetPassword: duxFactory({
    apiFn: api.postResetPassword,
    actionPrefix: 'POST_RESET_PASSWORD'
  }),
  postVerifyForgotPasswordToken: duxFactory({
    apiFn: api.postVerifyForgotPasswordToken,
    actionPrefix: 'POST_VERIFY_FORGOT_PASSWORD_TOKEN'
  }),
  postVerifyAccount: duxFactory({
    apiFn: api.postVerifyAccount,
    actionPrefix: 'POST_VERIFY_ACCOUNT'
  }),
  getCampaignUser: duxFactory({
    apiFn: api.getCampaignUser,
    actionPrefix: 'GET_CAMPAIGN_USER'
  }),
  // END IDENTITY

  getLookups: duxFactory({
    apiFn: api.getLookups,
    actionPrefix: 'GET_LOOKUPS'
  }),

  // SHOP
  postAddToCart: duxFactory({
    apiFn: api.postAddToCart,
    actionPrefix: 'POST_ADD_TO_CART'
  }),
  putCartItem: duxFactory({
    apiFn: api.putCartItem,
    actionPrefix: 'PUT_CART_ITEM'
  }),
  deleteCartItem: duxFactory({
    apiFn: api.deleteCartItem,
    actionPrefix: 'DELETE_CART_ITEM'
  }),
  getShopProductBySlug: duxFactory({
    apiFn: api.getShopProductBySlug,
    actionPrefix: 'GET_SHOP_PRODUCTS_BY_SLUG'
  }),
  getShopProducts: duxFactory({
    apiFn: api.getShopProducts,
    actionPrefix: 'GET_SHOP_PRODUCTS'
  }),
  // END SHOP

  // CHECKOUT
  getCart: duxFactory({
    apiFn: api.getCart,
    actionPrefix: 'GET_CART',
    persist: true,
    successListeners: [
      'POST_ADD_TO_CART',
      'POST_CART_SHIPPING',
      'POST_CART_BILLING',
      'DELETE_CART_ITEM',
      'PUT_CART_ITEM',
      'GET_CAMPAIGN_CART',
      'POST_PROMO_CODE',
      'DEL_PROMO_CODE'
    ]
  }),
  getCampaignCart: duxFactory({
    apiFn: api.getCampaignCart,
    actionPrefix: 'GET_CAMPAIGN_CART',
  }),
  postCartShipping: duxFactory({
    apiFn: api.postCartShipping,
    actionPrefix: 'POST_CART_SHIPPING'
  }),
  getCartShipping: duxFactory({
    apiFn: api.getCartShipping,
    actionPrefix: 'GET_CART_SHIPPING',
    successListeners: [
      'POST_CART_SHIPPING'
    ],
    requestListeners: [
      'POST_CART_SHIPPING'
    ],
    errorListeners: [
      'POST_CART_SHIPPING'
    ]
  }),
  postCartBilling: duxFactory({
    apiFn: api.postCartBilling,
    actionPrefix: 'POST_CART_BILLING'
  }),
  getCartBilling: duxFactory({
    apiFn: api.getCartBilling,
    actionPrefix: 'GET_CART_BILLING',
    requestListeners: [
      'POST_CART_BILLING'
    ]
  }),
  postValidateAddress: duxFactory({
    apiFn: api.postValidateAddress,
    actionPrefix: 'POST_VALIDATE_ADDRESS'
  }),
  postCompleteOrder: duxFactory({
    apiFn: api.postCompleteOrder,
    actionPrefix: 'POST_COMPLETE_ORDER'
  }),
  postCompleteBulkOrders: duxFactory({
    apiFn: api.postCompleteBulkOrders,
    actionPrefix: 'POST_COMPLETE_BULK_ORDERS'
  }),
  sendBulkOrderEmail: duxFactory({
    apiFn: api.sendBulkOrderEmail,
    actionPrefix: 'SEND_BULK_ORDER_EMAIL'
  }),
  getBulkCarts: duxFactory({
    apiFn: api.getBulkCarts,
    actionPrefix: 'GET_BULK_CARTS',
  }),
  getCheckEmail: duxFactory({
    apiFn: api.getCheckEmail,
    actionPrefix: 'GET_CHECK_EMAIL'
  }),
  postPromoCode: duxFactory({
    apiFn: api.postPromoCode,
    actionPrefix: 'POST_PROMO_CODE'
  }),
  delPromoCode: duxFactory({
    apiFn: api.delPromoCode,
    actionPrefix: 'DEL_PROMO_CODE'
  }),
  // END CHECKOUT

  // ACCOUNT
  getOrderHistory: duxFactory({
    apiFn: api.getOrderHistory,
    actionPrefix: 'GET_ORDER_HISTORY'
  }),
  postNameAndEmail: duxFactory({
    apiFn: api.postNameAndEmail,
    actionPrefix: 'POST_NAME_AND_EMAIL'
  }),
  putBirthday: duxFactory({
    apiFn: api.putBirthday,
    actionPrefix: 'PUT_BIRTHDAY'
  }),
  putPassword: duxFactory({
    apiFn: api.putPassword,
    actionPrefix: 'PUT_PASSWORD'
  }),
  postEmailPref: duxFactory({
    apiFn: api.postEmailPref,
    actionPrefix: 'POST_EMAIL_PREF'
  }),
  deleteAddress: duxFactory({
    apiFn: api.deleteAddress,
    actionPrefix: 'DEL_ADDRESS'
  }),
  putAddress: duxFactory({
    apiFn: api.putAddress,
    actionPrefix: 'PUT_ADDRESS'
  }),
  postAddress: duxFactory({
    apiFn: api.postAddress,
    actionPrefix: 'POST_ADDRESS'
  }),
  deleteCard: duxFactory({
    apiFn: api.deleteCard,
    actionPrefix: 'DEL_CARD'
  }),
  postCard: duxFactory({
    apiFn: api.postCard,
    actionPrefix: 'POST_CARD'
  }),
  putCard: duxFactory({
    apiFn: api.putCard,
    actionPrefix: 'PUT_CARD'
  }),
  postSignUp: duxFactory({
    apiFn: api.postSignUp,
    actionPrefix: 'POST_SIGN_UP'
  }),
  // END ACCOUNT
  getFindOurWines: duxFactory({
    apiFn: api.getFindOurWines,
    actionPrefix: 'GET_FIND_OUR_WINES'
  }),
  postSubscribe: duxFactory({
    apiFn: api.postSubscribe,
    actionPrefix: 'POST_SUBSCRIBE'
  }),
  findFedexLocation: duxFactory({
    apiFn: api.findFedexLocation,
    actionPrefix: 'FIND_FEDEX_LOCATION'
  }),
  getAddressSuggestions: duxFactory({
    apiFn: api.getAddressSuggestions,
    actionPrefix: 'GET_ADDRESS_SUGGESTIONS'
  })
};
