import React from 'react';
import ReactModal from 'react-modal';
import { withStyles } from '@material-ui/styles';
import close from '../../../../assets/vectors/Close.svg';
import Button from '../../../bits/Shared/Button';
import AddressForm from '../../Forms/Address';
import { joinClasses, capitalizeFLetter } from '../../../../utils';
import styles from './styles';

ReactModal.setAppElement(document.getElementById('root'));

const AddressModal = ({ 
  classes, 
  isOpen, 
  onClose, 
  onCancel,
  context, 
  stateOptions,
  countryOptions,
  setNewAddress,
  address2,
  company,
  saveAsDefaultAllowed,
  addressInvalid,
  clear,
  onMakeDefaultAddress,
  isMarkedSaveDefault,
  formValues,
  changeState,
  states,
  selectedCountry,
  handleBlur,
  addressSuggestions,
  onSelectSuggestedAddress,
  change,
  searchAddress,
  handleSelect,
  onSearchAddress,
  address1,
  missingAddress1,
  setMissingAddress1
}) => {
  const [submitted, setSubmitted] = React.useState(false);
  const isBilling = context === 'Billing';

  const closeModal = e => {
    e.preventDefault();
    onClose();
  };

  const{
    zip, 
    city,
    stateId 
  } = formValues || {};

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onCancel}
      className={classes.container}
      overlayClassName="react-modal"
    >
      <p className={joinClasses('h5', classes.heading)}>
        {`Enter a New ${capitalizeFLetter(context)} Address`}
      </p>
      <AddressForm 
        countryField={isBilling} 
        stateOptions={stateOptions} 
        countryOptions={countryOptions}
        address2={address2} 
        company={company} 
        isBilling={isBilling}
        saveAsDefaultAllowed={saveAsDefaultAllowed}
        clear={clear}
        onMakeDefaultAddress={onMakeDefaultAddress}
        isMarkedSaveDefault={isMarkedSaveDefault}
        city={city}
        zip={zip}
        stateId={stateId}
        changeState={changeState}
        states={states}
        selectedCountry={selectedCountry}
        onSearchAddress={onSearchAddress}
        handleBlur={handleBlur}
        addressSuggestions={addressSuggestions}
        onSelectSuggestedAddress={onSelectSuggestedAddress}
        change={change}
        searchAddress={searchAddress}
        handleSelect={handleSelect}
        address1={address1}
      />
      {addressInvalid && submitted && (
        <p style={{ color: 'red' }}>Missing required information. Please check form and resubmit.</p>
      )}
      {missingAddress1&& (
        <p style={{ color: 'red' }}>Missing required information. Please check form and resubmit.</p>
      )}
      <Button
        onClick={e =>{
          if (!address1 || address1.length <2 ) {
            setMissingAddress1(true);
            return
          }
          setSubmitted(true);
          if (addressInvalid) return;
          setNewAddress();
          closeModal(e);
        }}
        className={classes.button}
        title="Use This Address"
      />
      <button type="button" onClick={onCancel} className={classes.close}>
        <img src={close} alt="Close overlay" />
      </button>
    </ReactModal>
  );
};

export default withStyles(styles)(AddressModal);
